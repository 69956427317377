import { HealthcheckModel, Question, QuestionGroup } from "./HealthcheckModel";

export interface HealthcheckResultModel {
    id?: string
    userId: string
    healthcheckModel?: HealthcheckModel
    subject: EvaluatedSubject
    createdAt: Date
    updatedAt?: Date
    resultGroups: ResultGroup[]
}

export interface HealthcheckResultListModel extends HealthcheckResultModel {
    resultStats?: ResultStats
}

export interface ResultStats {
    questions: number | null,
    success: number | null,
    fail: number | null,
    unavailable: number | null
}

export interface ResultStatsView extends ResultStats {
    userId: string,
    updatedAt: string
}

export interface EvaluatedSubject {
    organization?: EvaluatedOrganization
    unit?: EvaluatedUnit
    target?: EvaluatedTarget
}

export interface EvaluatedOrganization {
    id?: string
    name: string
    type: string
}
export interface EvaluatedUnit {
    id?: string
    name: string
    type: string
}

export interface EvaluatedTarget {
    id?: string
    name: string
    type: string
}

export interface ResultGroup extends QuestionGroup {
    results: QuestionResult[]
}

export interface QuestionResult extends Question {
    answer?: number | string | boolean | null;
    result: QuestionResultLevels // success percentage of respective outcome
}

export enum QuestionResultLevels {
    success = 100,
    fail = 0
}
