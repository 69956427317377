import type { User } from "@supabase/supabase-js";
import React from "react";
import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { countHealthchecks, deleteHealthcheck, fetchHealthchecks } from "../../lib/HealthcheckApi";
import { HealthcheckModel } from "../../models/HealthcheckModel";
import { Header } from "../common/Header";
import HealthcheckListItem from "./HealthcheckListItem";

const PER_PAGE = 5;

const HealthchecksOverview = ({ user }: { user: User }) => {
    const [healthchecks, setHealthchecks] = useState<HealthcheckModel[]>([]);
    const [numHealthchecks, setNumHealthchecks] = useState<number>(0);

    let { page } = useParams();
    const currentPage = parseInt(page ?? "1") ?? 1;

    useEffect(() => {
        fetchHealthchecks((currentPage - 1) * PER_PAGE, (currentPage * PER_PAGE) - 1).then(data => setHealthchecks(data))
    }, [currentPage]);

    useEffect(() => {
        countHealthchecks().then(data => setNumHealthchecks(data ?? 0))
    }, [currentPage]);

    const deleteHealthcheckEvent = async (hcId: string) => {
        deleteHealthcheck(hcId, user).then(_ => setHealthchecks(healthchecks.filter((x) => x.hcId! !== hcId)))
    };

    const pageUrl = (pageIdx: Number) => {
        return `/healthchecks/${pageIdx}`
    };

    return (
        <div>
            <Header title="Healthchecks" buttons={[
                <NavLink
                    to='new'
                    key="new"
                    className='btn btn-primary'
                >
                    New healthcheck
                </NavLink>
            ]} />
            <main>
                <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                    <div
                        className={"flex flex-col flex-grow p-4"}
                    >

                        {healthchecks.length ? (
                            healthchecks.map((healthcheck) => (
                                <HealthcheckListItem
                                    key={healthcheck.id}
                                    healthcheck={healthcheck}
                                    onDelete={() => deleteHealthcheckEvent(healthcheck.hcId!)}
                                />
                            ))
                        ) : (
                            <span
                                className={
                                    "h-full flex justify-center items-center"
                                }
                            >
                                Loading healthchecks...
                            </span>
                        )}
                        <div className="mx-auto">
                            <div className="btn-group">

                                {currentPage > 1 ? (
                                    <NavLink to={pageUrl(currentPage - 1)} className='btn btn-accent'>
                                        « Previous page
                                    </NavLink>
                                ) : (<></>)
                                }

                                {(PER_PAGE*currentPage - numHealthchecks) < 0 ? (
                                    <NavLink to={pageUrl(currentPage + 1)} className='btn btn-accent'>
                                        Next page »
                                    </NavLink>
                                ) : (<></>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default HealthchecksOverview;
