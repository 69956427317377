import { User } from "@supabase/supabase-js";
import * as React from "react";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { createEmptyHealtcheckResult, loadHealtcheckResult, saveHealthcheckResult } from "../../lib/HealthcheckResultApi";
import { ErrorModal } from "../common/ErrorDialog";
import { Header } from "../common/Header";
import { Form, Formik, FormikHelpers } from 'formik';
import { SubjectInfoForm } from "./evaluation/SubjectInfoForm";
import { HealthcheckResultModel } from "../../models/HealthcheckResultModel";
import { ResultGroups } from "./evaluation/ResultGroups";

export const EvaluationForm = ({ user }: { user: User }) => {

    const [errorText, setError] = useState<string | null>("");
    const [healthcheckResult, setHealthcheckResult] = useState<HealthcheckResultModel>();

    let { hcId, hcResultId, groupIndex } = useParams();

    const currentIndex = parseInt(groupIndex ?? "1") ?? 1;
    const cancelUrl = hcResultId ? `/healthcheck-results/${hcResultId}` : "/healthchecks"    
    const prevGroupUrl = hcResultId ? `/healthcheck-results/${hcResultId}/edit/${currentIndex-1}` : `/healthchecks/${hcId}/evaluate/${currentIndex-1}`
    const nextGroupUrl = hcResultId ? `/healthcheck-results/${hcResultId}/edit/${currentIndex+1}` : `/healthchecks/${hcId}/evaluate/${currentIndex+1}`

    useEffect((localHcId: string | undefined = hcId, localHcResultId: string | undefined = hcResultId, localUser: User = user) => {
        const fetchHealthcheckResult = async () => {
            if (!healthcheckResult) {
                const healthcheckResult = await loadHealtcheckResult(localHcId, localHcResultId, localUser)
                setHealthcheckResult(healthcheckResult)
            }
        }
        fetchHealthcheckResult().catch(console.error)
    }, [hcId, hcResultId, user, healthcheckResult]);

    const navigate = useNavigate();
    
    const submitEvaluation = async (values: HealthcheckResultModel, actions: FormikHelpers<HealthcheckResultModel>) => {
        var e = await saveHealthcheckResult(values, user)
        if (e) setError(e.message);

        actions.setSubmitting(false);
        if (!e) {
            navigate(`/healthcheck-results/${values.id}`);
        }
    };

    const initialValues: HealthcheckResultModel = createEmptyHealtcheckResult(user);

    return (
        <>
            <Header title="Healthcheck evaluation" buttons={[
                <NavLink key="cancel" to={cancelUrl} className='btn btn-ghost mr-4'>
                    Cancel
                </NavLink>
            ]} />
            <main>
                <Formik
                    initialValues={healthcheckResult ?? initialValues}
                    onSubmit={submitEvaluation}
                    enableReinitialize={true}
                >
                    <Form>
                        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                            {!!errorText && (
                                <ErrorModal errorTitle={"Failed to create healthcheck"} errorText={errorText} />
                            )}
                            <div className="md:grid md:grid-cols-3 md:gap-6">
                                <div className="md:col-span-1">
                                    <div className="px-4 sm:px-0">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Subject</h3>
                                        <p className="mt-1 text-sm text-gray-600">
                                            Information about evaluated subject
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-5 md:col-span-2 md:mt-0">
                                    <SubjectInfoForm />
                                </div>
                                <div className="md:col-span-1">
                                    <div className="px-4 sm:px-0">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Healthcheck questions</h3>
                                        <p className="mt-1 text-sm text-gray-600">
                                            Fill-in measured or observed values
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-5 md:col-span-2 md:mt-0">
                                    <ResultGroups />
                                </div>
                            </div>
                        </div>

                        <div className="bg-white">
                            <div className="md:grid md:grid-cols-3 md:gap-6 mx-auto max-w-7xl sm:px-6">
                                <div className="md:col-span-1"></div>
                                <div className="md:col-span-2">
                                    <div className="flex mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
                                        <div className="w-full mr-4">
                                            {currentIndex > 1 && (
                                                <NavLink to={prevGroupUrl} className='btn btn-warning'>
                                                    Previous page
                                                </NavLink>
                                            )}
                                        </div>
                                        <NavLink to={cancelUrl} className='btn btn-ghost mr-4'>
                                            Cancel
                                        </NavLink>
                                        {currentIndex === healthcheckResult?.resultGroups.length ? (
                                        <button type="submit" className='btn btn-accent'>
                                            Save
                                        </button>
                                        ) : (
                                            <NavLink to={nextGroupUrl} className='btn btn-accent'>
                                                Next page
                                            </NavLink>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </main>
        </>
    )
}