import { Field, FieldInputProps, FieldProps, useFormikContext } from "formik";
import * as React from "react";
import { HealthcheckModel, NumRange, NumRangeOutcome, Question } from "../../../../models/HealthcheckModel";

export const RangeOutcomeForm = ({questionIndex, groupIndex, question} : {questionIndex: number, groupIndex: number, question: Question}) => {
    const context = useFormikContext<HealthcheckModel>();

    function recast(e: React.ChangeEvent<any>, field: FieldInputProps<HealthcheckModel>, values: HealthcheckModel, from: boolean) {
        !question.outcomes[0] && (question.outcomes[0] = new NumRangeOutcome(100, new NumRange(0, 100)));
        
        var outcome: NumRangeOutcome = question.outcomes[0];
        
        from ? outcome.expectedResult!.from = parseInt(e.target.value) : outcome.expectedResult!.to = parseInt(e.target.value)
        
        question.outcomes[0] = outcome;

        context.setValues(values)
    }
    
    return (
        <div className="form-control w-full max-w-xs">
            <span className="label-text mt-2">Expected outcome</span>
            <Field name={`questionGroups[${groupIndex}].questions[${questionIndex}].outcomes[0].expectedResult.from`}>
                {({ field, form }: FieldProps) => 
                    (
                        <label className="input-group mt-2">
                            <span className="bg-gray-200">From</span>
                            <input 
                                type="number" 
                                id={`questionGroups[${groupIndex}].questions[${questionIndex}].outcomes[0].expectedResult.from`} 
                                className="input input-bordered input-success w-full max-w-xs"
                                onChange={e => recast(e, field, form.values, true)} 
                                value={(question.outcomes[0] as NumRangeOutcome).expectedResult?.from} 
                            />
                        </label>
                    )
                    
                }
            </Field>
            <Field name={`questionGroups[${groupIndex}].questions[${questionIndex}].outcomes[0].expectedResult.to`}>
                {({ field, form }: FieldProps) => (
                        <label className="input-group mt-2">
                            <span className="bg-gray-200">To</span>
                            <input 
                                type="number" 
                                id={`questionGroups[${groupIndex}].questions[${questionIndex}].outcomes[0].expectedResult.to`} 
                                className="input input-bordered input-success w-full max-w-xs"
                                onChange={e => recast(e, field, form.values, false)} 
                                value={(question.outcomes[0] as NumRangeOutcome).expectedResult?.to} 
                            />
                        </label>
                    )
                }
            </Field>
        </div>
    )

} 