import * as React from "react";
import { HealthcheckResultModel } from "../../../models/HealthcheckResultModel";
import { PerQuestionBarChart } from "./charts/PerQuestionBarChart";
import { OverallPieChart } from "./charts/OverallPieChart";
import { PerGroupDonutChart } from "./charts/PerGroupDonutChart";

export const OverviewCharts = ({ healthcheckResult }: { healthcheckResult: HealthcheckResultModel }) => {

    return (
        <div className="bg-white rounded-lg">
            <div className="grid grid-cols-2 p-5">
                <div className="col-span-1 w-full">
                    <OverallPieChart healthcheckResult={healthcheckResult} />
                </div>
                <div className="col-span-1 w-full">
                    <PerGroupDonutChart healthcheckResult={healthcheckResult} />
                </div>
                <div className="col-span-2 w-full">
                    <PerQuestionBarChart healthcheckResult={healthcheckResult} />
                </div>
            </div>
        </div>
    )

}