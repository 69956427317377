import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Field, useFormikContext } from "formik";
import * as React from "react";
import { v4 } from "uuid";
import { BooleanOutcome, HealthcheckModel, QuestionGroup, QuestionType } from "../../../models/HealthcheckModel";
import { HealthcheckQuestion } from "./HealthcheckQuestion";

export const QuestionGroupForm = ({ group, groupIndex, onDelete }: { group: QuestionGroup, groupIndex: number, onDelete: (values: HealthcheckModel, index: number) => void }) => {
    const context = useFormikContext<HealthcheckModel>();

    function addQuestion(values: HealthcheckModel) {
        group.questions.push({
            id: v4(),
            title: "",
            description: "",
            type: QuestionType.BOOLEAN,
            outcomes: [
                new BooleanOutcome(100, true)
            ]
        });

        context.setValues(values)
    }

    function removeQuestion(values: HealthcheckModel, index: number) {
        group.questions.splice(index, 1)
        context.setValues(values)
    }

    return (    
        <div className="grid grid-cols-1 p-4 border-4 border-dashed border-gray-300 rounded mb-4">
            <div className="flex mb-2">
                <div className="w-full">
                    <h2 className="card-title">{group.title === "" ? "New group" : group.title}</h2>
                </div>
                <div>
                    <button className="btn btn-circle btn-outline btn-error" type="button" title="Delete group" onClick={() => onDelete(context.values, groupIndex)}>
                        <TrashIcon className="h-6 w-6" />
                    </button>
                </div>
            </div>
            <div className="bg-white rounded-lg p-4 mb-4">
                <div className="w-full">
                    <label htmlFor={`questionGroups[${groupIndex}].title`} className="label">
                        <span className="label-text">Group name</span>
                    </label>
                    <Field 
                        name={`questionGroups[${groupIndex}].title`}
                        id={`questionGroups[${groupIndex}].title`}
                        className={`input input-bordered w-full max-w-xs`}
                    />
                </div>
                <div className="w-full">
                    <label htmlFor={`questionGroups[${groupIndex}].description`} className="label">
                        <span className="label-text">Group description</span>
                    </label>
                    <Field 
                        as="textarea"
                        id={`questionGroups[${groupIndex}].description`}
                        name={`questionGroups[${groupIndex}].description`}
                        className={`textarea textarea-bordered h-24 w-full`}
                    />
                </div>
            </div>
            {group.questions!.map((question, i) => (
                <HealthcheckQuestion key={i} question={question} index={i} groupIndex={groupIndex} onDelete={removeQuestion}/>
            ))}
            <div className="bg-white rounded-lg p-4">
                <div className="grid grid-cols-1">
                    <button type="button" className="btn gap-2 m-8 btn-success" onClick={() => addQuestion(context.values)}>
                        <PlusIcon className="h-6 w-6"/>
                        Add question
                    </button>
                </div>
            </div>
            
        </div>

    )

}