import { useFormikContext } from "formik";
import * as React from "react";
import { useParams } from "react-router";
import { HealthcheckResultModel } from "../../../models/HealthcheckResultModel";
import { GroupSteps } from "./GroupSteps";
import { QuestionResultsForm } from "./QuestionResultsForm";

export const ResultGroups = () => {
    const context = useFormikContext<HealthcheckResultModel>();
    var resultGroups = context.values.resultGroups
    let { hcId, hcResultId, groupIndex } = useParams();

    const currentIndex = parseInt(groupIndex ?? "1") ?? 1;
    const nextGroupUrl = hcResultId ? `/healthcheck-results/${hcResultId}/edit` : `/healthchecks/${hcId}/evaluate`

    return (
        <>
            {(resultGroups && resultGroups.length >= currentIndex) ? (
                <>
                    <GroupSteps resultGroups={resultGroups} currentIndex={currentIndex} nextGroupUrl={nextGroupUrl}/>

                    <QuestionResultsForm
                        groupIndex={currentIndex - 1}
                        resultGroup={resultGroups[currentIndex - 1]}
                    />

                    <GroupSteps resultGroups={resultGroups} currentIndex={currentIndex} nextGroupUrl={nextGroupUrl}/>
                </>
            ) : (
                <span
                    className={
                        "h-full flex justify-center items-center"
                    }
                >
                    No question groups defined in Healthcheck
                </span>
            )}
        </>
    )

}