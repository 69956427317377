import { User } from "@supabase/supabase-js";
import * as React from "react";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { loadHealthcheck, saveHealthcheck } from "../../lib/HealthcheckApi";
import { ErrorModal } from "../common/ErrorDialog";
import { Header } from "../common/Header";
import { Form, Formik, FormikHelpers } from 'formik';
import { HealthcheckModel } from "../../models/HealthcheckModel";
import { BaseHealthCheckInfoForm } from "./components/BaseHealthcheckInfoForm";
import { QuestionGroupsForm } from "./components/QuestionGroupsForm";

const HealthcheckCreateForm = ({ user }: { user: User }) => {

    const [errorText, setError] = useState<string | null>("");
    const [healthcheck, setHealthcheck] = useState<HealthcheckModel>();

    let { hcId } = useParams();

    useEffect((localHcId: string | undefined = hcId, localUser: User = user) => {
        const fetchHealthcheck = async () => {
            if (!healthcheck) {
                if (!localHcId) {
                    setHealthcheck({ title: "", description: "", questionGroups: [] })
                    return
                }
                const healthcheck = await loadHealthcheck(localHcId, localUser);
                setHealthcheck(healthcheck)
            }
        };
        fetchHealthcheck().catch(console.error)
    }, [hcId, user, healthcheck]);

    const navigate = useNavigate();

    const submitHealthcheck = async (values: HealthcheckModel, actions: FormikHelpers<HealthcheckModel>) => {
        var e = await saveHealthcheck(values, user)
        if (e) setError(e.message);

        actions.setSubmitting(false);
        if (!e) {
            navigate("/healthchecks");
        }
    };

    return (

        <>
            <Header title={`${hcId ? `Edit` : `Create`} Healthcheck`} buttons={[
                <NavLink key="cancel" to='/healthchecks' className='btn btn-ghost mr-4'>
                    Cancel
                </NavLink>
            ]} />
            <main>
                {healthcheck ? (
                    <Formik
                        initialValues={healthcheck}
                        onSubmit={submitHealthcheck}
                        enableReinitialize={true}
                    >
                        <Form>
                            <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                                {!!errorText && (
                                    <ErrorModal errorTitle={"Failed to create healthcheck"} errorText={errorText} />
                                )}
                                <div className="md:grid md:grid-cols-3 md:gap-6">
                                    <div className="md:col-span-1">
                                        <div className="px-4 sm:px-0">
                                            <h3 className="text-lg font-medium leading-6 text-gray-900">Healthcheck info</h3>
                                            <p className="mt-1 text-sm text-gray-600">
                                                Basic healthcheck information.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-5 md:col-span-2 md:mt-0">
                                        <BaseHealthCheckInfoForm />
                                    </div>
                                    <div className="md:col-span-1">
                                        <div className="px-4 sm:px-0">
                                            <h3 className="text-lg font-medium leading-6 text-gray-900">Questions</h3>
                                            <p className="mt-1 text-sm text-gray-600">
                                                These are the questions that are filled-in during healthcheck evaluation.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-5 md:col-span-2 md:mt-0">
                                        <QuestionGroupsForm />
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white">
                                <div className="flex mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
                                    <div className="w-full mr-4"></div>
                                    <NavLink to='/healthchecks' className='btn btn-ghost mr-4'>
                                        Cancel
                                    </NavLink>
                                    <button type="submit" className='btn btn-accent'>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                ) : (
                    
                    <div className=" bg-white mt-4 px-4 py-5 text-center rounded-lg">
                        Loading...
                    </div>
                )}
            </main>
        </>
    )


}

export default HealthcheckCreateForm;