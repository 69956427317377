import { User } from "@supabase/supabase-js"
import { v4 } from "uuid"
import { Question, QuestionGroup } from "../models/HealthcheckModel"
import { EvaluatedSubject, HealthcheckResultListModel, HealthcheckResultModel, QuestionResult, QuestionResultLevels, ResultGroup, ResultStats, ResultStatsView } from "../models/HealthcheckResultModel"
import { documentPath, supabase } from "./api"
import { loadHealthcheck } from "./HealthcheckApi"

export const createEmptyHealtcheckResult = (user: User) => {
    const idName = {
        id: undefined,
        name: ""
    }

    var subject: EvaluatedSubject = {
        organization: { ...idName, type: "organization" },
        unit: { ...idName, type: "unit" },
        target: { ...idName, type: "target" }
    }

    var healtcheckResultModel: HealthcheckResultModel = {
        subject: subject,
        userId: user.id,
        healthcheckModel: { title: "", description: "", questionGroups: [] },
        createdAt: new Date(),
        resultGroups: []
    }

    return healtcheckResultModel
}

export const loadHealtcheckResult = async (hcId: string | undefined, hcResultId: string | undefined, user: User) => {
    var hcResult = createEmptyHealtcheckResult(user)

    if (hcResultId) {
        let { data, error } = await supabase.storage
            .from("healthcheck-results")
            .download(documentPath(user.id, hcResultId))

        if (error) {
            console.log("error", error)
            throw Error("Failed to load");

        }

        var jsonData = await data?.text()
        return JSON.parse(jsonData!) as HealthcheckResultModel
    }

    if (!hcId) {
        throw Error("Missing healthcheck id");
    }

    const healthcheckModel = await loadHealthcheck(hcId, user)
    hcResult.healthcheckModel = healthcheckModel
    hcResult.resultGroups = healthcheckModel.questionGroups!.map((q: QuestionGroup) => questionGroupToResultGroupMapper(q))

    return hcResult
}

const questionGroupToResultGroupMapper = (q: QuestionGroup) => {
    var resultGroup = q as ResultGroup
    resultGroup.results = q.questions.map((q: Question) => q as QuestionResult)
    return resultGroup
}

export const saveHealthcheckResult = async (values: HealthcheckResultModel, user: User) => {
    values.id = values.id ?? v4()

    createTargets(values, user)

    let { error: uploadError } = await supabase.storage
        .from("healthcheck-results")
        .upload(documentPath(user.id, values.id), JSON.stringify(values), { upsert: true })

    if (uploadError) return uploadError


    let { error: upsertError } = await supabase
        .from("results")
        .upsert({
            id: values.id,
            user_id: user.id,
            created_at: new Date(values.createdAt).toISOString(),
            updated_at: new Date().toISOString(),
            title: values.healthcheckModel!.title,
            description: values.healthcheckModel!.description,
            result_stats: {
                questions: values.resultGroups.flatMap(group => group.results).length,
                success: values.resultGroups.flatMap(group => group.results).filter((res) => res.result === QuestionResultLevels.success).length,
                fail: values.resultGroups.flatMap(group => group.results).filter((res) => res.result === QuestionResultLevels.fail).length,
                unavailable: values.resultGroups.flatMap(group => group.results).filter((res) => res.result === undefined).length
            },
            results: values.resultGroups as {}
        })
        .single();

    bindHCResultsAndTargets(values, user)

    if (upsertError) return upsertError
}

const createTargets = async (values: HealthcheckResultModel, user: User) => {
    [
        values.subject.organization,
        values.subject.target,
        values.subject.unit
    ].forEach(async (target) => {
        if (!target!.id) {
            target!.id = v4()
        }
        let { error: upsertError } = await supabase
            .from("targets")
            .upsert({ id: target!.id, created_at: new Date().toISOString(), user_id: user.id, name: target!.name, type: target!.type })
            .single();

        if (upsertError) throw upsertError
    })
}

const bindHCResultsAndTargets = async (values: HealthcheckResultModel, user: User) => {
    [
        values.subject.organization,
        values.subject.target,
        values.subject.unit
    ].forEach(async (target) => {
        if (!target!.id) {
            target!.id = v4()
        }
        let { error: upsertError } = await supabase
            .from("subjects")
            .upsert({ result_id: values.id!, target_id: target!.id, user_id: user.id })
            .single();

        if (upsertError) throw upsertError

    })
}

export const fetchPerUserStatistics = async (user: User, limit: number = 10) => {
    let { data, error } = await supabase
        .from("result_stats")
        .select("success, fail, questions, unavailable, userId:user_id, updatedAt:updated_at")
        .limit(limit)
    if (error) {
        throw error
    }

    return data as ResultStatsView[];
}

export const fetchHealthcheckResults = async (from: number, to: number) => {
    let { data, error } = await supabase
        .from("results")
        .select("*, targets(*)")
        .order("created_at", { ascending: false })
        .range(from, to);
    if (error) {
        console.log("error", error);
        throw error
    }

    var hcr = data?.map(result => {
        var org, unit, target = null

        if (Array.isArray(result.targets)) {
            org = result.targets.filter(v => v.type === "organization").pop()
            unit = result.targets.filter(v => v.type === "unit").pop()
            target = result.targets.filter(v => v.type === "target").pop()
        }
        return {
            id: result.id,
            userId: result.user_id,
            subject: {
                organization: org,
                unit: unit,
                target: target
            },
            healthcheckModel: {
                title: result.title,
                description: result.description,
                questionGroups: []
            },
            createdAt: new Date(result.created_at!),
            resultGroups: result.results ? (result.results as []).map(e => e as ResultGroup) : [],
            resultStats: result.result_stats as {} as ResultStats
        }
    })
    return hcr as HealthcheckResultListModel[]
};

export const deleteHealthcheckResult = async (id: string, user: User) => {
    let { error } = await supabase.storage.from("results").remove([documentPath(user.id, id)])
    if (error) return error

    let { error: subjectDbError } = await supabase.from("subjects").delete().eq("result_id", id)
    if (subjectDbError) return subjectDbError

    let { error: dbError } = await supabase.from("results").delete().eq("id", id)
    if (dbError) return dbError

    return true
}