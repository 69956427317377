import * as React from "react";
import { BooleanOutcome, NumberOutcome, NumRangeOutcome, QuestionType, StringOutcome } from "../../../models/HealthcheckModel";
import { QuestionResult, QuestionResultLevels } from "../../../models/HealthcheckResultModel";

export const QuestionResultItem = ({ questionIndex, questionResult }: { questionIndex: number, questionResult: QuestionResult }) => {

    var expectedResult = ""
    var result = questionResult.answer !== undefined ? questionResult.answer + "" : "";

    switch (questionResult.type) {
        case QuestionType.BOOLEAN: {
            expectedResult = (questionResult.outcomes[0] as BooleanOutcome).expectedResult ? "True" : "False";
            result = questionResult.answer !== undefined ? (questionResult.answer ? "True" : "False") : "";
            break;
        }
        case QuestionType.NUMBER: expectedResult = (questionResult.outcomes[0] as NumberOutcome).expectedResult + ""; break;
        case QuestionType.STRING: expectedResult = (questionResult.outcomes[0] as StringOutcome).expectedResult + ""; break;
        case QuestionType.NUM_RANGE: {
            const out = (questionResult.outcomes[0] as NumRangeOutcome)
            expectedResult = out.expectedResult?.from + " - " + out.expectedResult?.to;
            break;
        }
    }

    return (
        <div className={`bg-white rounded-lg mb-4 p-6 border ${questionResult.result !== undefined && (questionResult.result === QuestionResultLevels.success ? "border-success" : "border-error")}`}>
            <div className="flex">
                <div className="w-full">
                    <h2 className="card-title">{questionIndex + 1}. {questionResult.title}</h2>
                </div>
            </div>

            <div className="flex">
                {questionResult.description}
            </div>

            <div className="grid grid-cols-4 mt-6">
                <div className="col-span-3">
                    <div className="form-control w-full max-w-xs">
                        <span>Measured value: <span className="font-semibold">{result}</span></span>
                        <span className="text-sm mt-2">
                            Expected value: <span className="font-bold">{expectedResult}</span>
                        </span>
                    </div>
                </div>
                <div className="col-span-1">
                    {questionResult.result !== undefined ? (
                        <div className="stats shadow w-full">
                            <div className="stat">
                                <div className="stat-title text-sm">Result</div>
                                {questionResult.result === QuestionResultLevels.success ? (
                                    <div className="stat-value text-success text-xl">Success</div>
                                ) : (
                                    <div className="stat-value text-error text-xl">Fail</div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>

    )

}