import { useState, useEffect } from "react";
import { supabase } from "./lib/api";
import HealthcheckOverview from "./components/healthcheck/HealthchecksOverview";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { E404 } from "./components/E404";
import { Dashboard } from "./components/dashboard/Dashboard";
import HealthcheckCreateForm from "./components/healthcheck/HealthcheckCreateForm"
import { EvaluationForm } from "./components/healthcheck/EvaluationForm";
import { HealthcheckResult } from "./components/healthcheck-result/HealthcheckResult";
import HealthcheckResultsOverview from "./components/healthcheck-result/HealthcheckResultsOverview";
import RecoverPassword from "./components/auth/RecoverPassword";
import { Login } from "./components/auth/Login";
import { TopNavigation } from "./components/common/TopNavigation";
import { Profile } from "./components/user/profile/Profile";
import { ExtendedUser } from "./models/UserModel";
import { fetchExtendedUser } from "./lib/UserApi";
import { User } from "@supabase/supabase-js";
import Documentation from "./components/documentation/Documentation";

interface IResults {
    access_token: string;
    refresh_token: string;
    expires_in: string;
    token_type: string;
    type: string;
}

function parseRecoveryToken() {
    let url = window.location.hash;
    let query = url.slice(1);
    let result: IResults = {
        access_token: "",
        refresh_token: "",
        expires_in: "",
        token_type: "",
        type: "",
    };

    query.split("&").forEach((part) => {
        const item = part.split("=");
        result[item[0] as keyof IResults] = decodeURIComponent(item[1]);
    });

    return result
}

function App() {
    const [user, setUser] = useState<ExtendedUser | null>(null);
    const [recoveryToken, setRecoveryToken] = useState<string | null>(null);

    useEffect(() => {
        let recoveryToken = parseRecoveryToken()
        if (recoveryToken.type === "recovery") {
            setRecoveryToken(recoveryToken.access_token);
        }

        supabase.auth.getSession().then(({ data: { session } }) => {
            fetchExtendedUser(session?.user as User).then(extendedUser => setUser(extendedUser))
        })

        const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
            const currentUser = session?.user
            if(currentUser) {
                fetchExtendedUser(currentUser).then(extendedUser => setUser(extendedUser))
            }
        })

        return () => {
            authListener?.subscription.unsubscribe()
        }
    }, [])

    return (
        <BrowserRouter>
            <div className="min-w-full min-h-screen flex justify-center bg-gray-200">
                {!user ? <Login /> : (
                    recoveryToken ? <RecoverPassword recoveryToken={recoveryToken} setRecoveryToken={setRecoveryToken}/> : (
                        <div className="min-h-full min-w-full ">
                            <TopNavigation user={user}/>
                            <Routes>
                                <Route path="/" element={<Dashboard user={user} />} />
                                <Route path="/healthchecks/" element={<HealthcheckOverview user={user} />} />
                                <Route path="/healthchecks/:page" element={<HealthcheckOverview user={user} />} />
                                <Route path="/healthchecks/new" element={<HealthcheckCreateForm user={user} />} />
                                <Route path="/healthchecks/:hcId/edit" element={<HealthcheckCreateForm user={user} />} />
                                <Route path="/healthchecks/:hcId/evaluate" element={<EvaluationForm user={user} />} />
                                <Route path="/healthchecks/:hcId/evaluate/:groupIndex" element={<EvaluationForm user={user} />} />
                                <Route path="/healthcheck-results" element={<HealthcheckResultsOverview user={user} />} />
                                <Route path="/healthcheck-results/:hcResultId" element={<HealthcheckResult user={user} />} />
                                <Route path="/healthcheck-results/:hcResultId/edit" element={<EvaluationForm user={user} />} />
                                <Route path="/healthcheck-results/:hcResultId/edit/:groupIndex" element={<EvaluationForm user={user} />} />

                                <Route path="/profile" element={<Profile user={user} />} />
                                <Route path="/docs" element={<Documentation user={user} />} />

                                <Route path="*" element={<E404 />} />
                            </Routes>

                        </div>
                    )

                )
                }
            </div>
        </BrowserRouter>
    );
}

export default App;
