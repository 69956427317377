import { CheckCircleIcon, ShieldCheckIcon, XCircleIcon } from "@heroicons/react/24/outline";
import type { AuthResponse, Provider } from "@supabase/supabase-js";
import * as React from "react";
import { useRef, useState } from "react";
import { supabase } from "../../lib/api";
import {
    REACT_APP_SUPABASE_KEY,
    REACT_APP_SUPABASE_URL,
} from "../../lib/constants";

export const Login = () => {
    const [helperText, setHelperText] = useState<{
        error: boolean;
        text: string | null;
    }>({ error: true, text: null });
    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);

    const handleLogin = async (type: String) => {
        const email = emailRef.current?.value!;
        const password = passwordRef.current?.value!;

        const authResponse: AuthResponse =
            type === "LOGIN"
                ? await supabase.auth.signInWithPassword({ email, password })
                : await supabase.auth.signUp({ email, password });

        if (authResponse.error) {
            setHelperText({ error: true, text: authResponse.error.message });
        } else if (!authResponse.data.user && !authResponse.error) {
            setHelperText({
                error: false,
                text: "An email has been sent to you for verification!",
            });
        }
    };

    const handleOAuthLogin = async (provider: Provider) => {
        // You need to enable the third party auth you want in Authentication > Settings
        // Read more on: https://supabase.com/docs/guides/auth#third-party-logins
        let { error } = await supabase.auth.signInWithOAuth({ provider });
        if (error) console.log("Error: ", error.message);
    };

    const forgotPassword = async (e: React.MouseEvent<Element, MouseEvent>) => {
        // Read more on https://supabase.com/docs/reference/javascript/reset-password-email#notes
        e.preventDefault();
        const email = prompt("Please enter your email:");

        if (email === null || email === "") {
            setHelperText({ error: true, text: "You must enter your email." });
        } else {
            let { error } = await supabase.auth.resetPasswordForEmail(
                email
            );
            if (error) {
                console.error("Error: ", error.message);
            } else {
                setHelperText({
                    error: false,
                    text: "Password recovery email has been sent.",
                });
            }
        }
    };

    return !REACT_APP_SUPABASE_URL || !REACT_APP_SUPABASE_KEY ? (
        <p>There is no api key and url</p>
    ) : (
        <div className="hero min-h-screen bg-base-200">
            <div className="hero-content flex-col lg:flex-row-reverse min-w-full">
                <div className="text-center lg:text-left p-6">
                    <div className="text-5xl font-bold">
                        <ShieldCheckIcon className="h-12 w-12 mr-2 inline-block" aria-hidden="true" />
                        Healthchk.app
                    </div>
                    <p className="py-6">Login to access the Healthchk.app dashboard.</p>
                </div>
                <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
                    <div className="card-body">
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Email</span>
                            </label>
                            <input
                                type={"email"}
                                name={"email"}
                                ref={emailRef}
                                required
                                placeholder="email"
                                className="input input-bordered"
                            />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Password</span>
                            </label>
                            <input
                                type={"password"}
                                name={"password"}
                                ref={passwordRef}
                                required
                                placeholder="password"
                                className="input input-bordered"
                            />
                            <label className="label">
                                <a href="#forgot-password" className="label-text-alt link link-hover" onClick={forgotPassword}>Forgot password?</a>
                            </label>
                        </div>
                        {!!helperText.text && (
                            <div className={`alert ${!!helperText.error ? "alert-error": "alert-success"} shadow-lg`}>
                                <div>
                                    {!!helperText.error ? (
                                        <XCircleIcon className="stroke-current flex-shrink-0 h-6 w-6" />
                                    ) : (
                                        <CheckCircleIcon className="stroke-current flex-shrink-0 h-6 w-6" />
                                    )}
                                    <span>{helperText.text}</span>
                                </div>
                            </div>
                        )}
                        <div className="form-control mt-6">
                            <div className="w-full">
                                <button
                                    className="btn btn-success w-full"
                                    type="submit"
                                    onClick={() =>
                                        handleLogin("LOGIN").catch(console.error)
                                    }
                                >
                                    Login
                                </button>
                            </div>
                        </div>
                        <div className="mt-3">
                            <div className="divider">Or</div>
                            <div className="w-full">
                                <button
                                    className="btn btn-primary w-full"
                                    type="submit"
                                    onClick={() =>
                                        handleLogin("REGISTER").catch(console.error)
                                    }
                                >
                                    Register
                                </button>
                            </div>
                        </div>
                        <div className="mt-3">
                            <div className="divider">Or continue with</div>

                            <div>
                                <div className="mt-3">
                                    <span className="block rounded-md shadow-sm">
                                        <button
                                            onClick={() => handleOAuthLogin("github")}
                                            type="button"
                                            className="w-3/4 mx-auto flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                        >
                                            GitHub
                                        </button>
                                    </span>
                                </div>
                                <div className="mt-3">
                                    <span className="block rounded-md shadow-sm">
                                        <button
                                            onClick={() => handleOAuthLogin("google")}
                                            type="button"
                                            className="w-3/4 mx-auto flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                                        >
                                            Google
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
