import * as React from "react";
import { useState } from "react";
import { QuestionType } from "../../../models/HealthcheckModel";
import { QuestionResult } from "../../../models/HealthcheckResultModel";
import { BooleanQuestion } from "./BooleanQuestion";
import { NumericQuestion } from "./NumericQuestion";
import { NumRangeQuestion } from "./NumericRangeQuestion";
import { TextQuestion } from "./TextQuestion";

export const SingleQuestionForm = ({ groupIndex, questionIndex, questionResult }: { groupIndex: number, questionIndex: number, questionResult: QuestionResult }) => {

    const [successOutcome, setSuccessOutcome] = useState<boolean | null>(null);

    return (
        <div className={`bg-white rounded-lg mb-4 p-6 border ${successOutcome !== null && (successOutcome ? "border-success" : "border-error")}`}>
            <div className="flex">
                <div className="w-full">
                    <h2 className="card-title">{questionIndex + 1}. {questionResult.title}</h2>
                </div>
            </div>

            <div className="flex">
                {questionResult.description}
            </div>

            <div className="grid grid-cols-4">
                <div className="col-span-3">
                    {questionResult.type === QuestionType.BOOLEAN && (
                        <BooleanQuestion questionResult={questionResult} groupIndex={groupIndex} questionIndex={questionIndex} outcomeSetter={setSuccessOutcome}/>
                    )}
                    {questionResult.type === QuestionType.NUMBER && (
                        <NumericQuestion questionResult={questionResult} groupIndex={groupIndex} questionIndex={questionIndex} outcomeSetter={setSuccessOutcome}/>
                    )}
                    {questionResult.type === QuestionType.NUM_RANGE && (
                        <NumRangeQuestion questionResult={questionResult} groupIndex={groupIndex} questionIndex={questionIndex} outcomeSetter={setSuccessOutcome}/>
                    )}
                    {questionResult.type === QuestionType.STRING && (
                        <TextQuestion questionResult={questionResult} groupIndex={groupIndex} questionIndex={questionIndex} outcomeSetter={setSuccessOutcome}/>
                    )}
                </div>
                <div className="col-span-1">
                    {successOutcome !== null ? (
                        <div className="stats shadow w-full">
                            <div className="stat">
                                <div className="stat-title text-sm">Result</div>
                                {successOutcome ? (
                                    <div className="stat-value text-success text-xl">Success</div>
                                ) : (
                                    <div className="stat-value text-error text-xl">Fail</div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    )

}