import { TrashIcon } from "@heroicons/react/24/outline";
import { Field, FieldInputProps, FieldProps, useFormikContext } from "formik";
import * as React from "react";
import { BooleanOutcome, HealthcheckModel, NumberOutcome, NumRange, NumRangeOutcome, Question, QuestionType, StringOutcome } from "../../../models/HealthcheckModel";
import { BooleanOutcomeForm } from "./outcomes/BooleanOutcomeForm";
import { NumericOutcomeForm } from "./outcomes/NumericOutcomeForm";
import { RangeOutcomeForm } from "./outcomes/RangeOutcomeForm";
import { TextOutcomeForm } from "./outcomes/TextOutcomeForm";

export const HealthcheckQuestion = ({ question, index, groupIndex, onDelete }: { question: Question, index: number, groupIndex: number, onDelete: (values: HealthcheckModel, index: number) => void; }) => {
    const context = useFormikContext<HealthcheckModel>();

    function changeType(e: React.ChangeEvent<any>, field: FieldInputProps<HealthcheckModel>, values: HealthcheckModel) {
        question.type = e.target.value;
        switch (question.type) {
            case QuestionType.BOOLEAN:
                question.outcomes = [new BooleanOutcome(100, true)];
                break;
            case QuestionType.NUMBER:
                question.outcomes = [new NumberOutcome(100, 0)];
                break;
            case QuestionType.NUM_RANGE:
                question.outcomes = [new NumRangeOutcome(100, new NumRange(0, 100))];
                break;
            case QuestionType.STRING:
                question.outcomes = [new StringOutcome(100, "")];
                break;
        }
        context.setFieldTouched(`questions[${index}].type`, true, false);
        context.setValues(values)
    }

    return (
        <div className="bg-white rounded-lg mb-4 p-6">
            <div className="flex">
                <div className="w-full">
                    <h2 className="card-title">Question {index + 1}</h2>
                </div>
                <div>
                    <button className="btn btn-circle btn-outline btn-error" type="button" title="Delete question" onClick={() => onDelete(context.values, index)}>
                        <TrashIcon className="h-6 w-6" />
                    </button>
                </div>
            </div>

            <div className="grid grid-cols-2">
                <div className="form-control w-full max-w-xs">
                    <label htmlFor={`questionGroups[${groupIndex}].questions[${index}].title`} className="label">
                        <span className="label-text">Title</span>
                    </label>
                    <Field 
                        type="text"
                        value={question.title} 
                        placeholder="Title" 
                        id={`questionGroups[${groupIndex}].questions[${index}].title`} 
                        name={`questionGroups[${groupIndex}].questions[${index}].title`} 
                        className="input input-bordered w-full max-w-xs" />
                    {context.touched.title && context.errors.title && <div className="inputError">{context.errors.title}</div>}

                    <label htmlFor={`questionGroups[${groupIndex}].questions[${index}].description`} className="label">
                        <span className="label-text">Description</span>
                    </label>
                    <Field 
                        as="textarea" 
                        value={question.description} 
                        placeholder="Description" 
                        id={`questionGroups[${groupIndex}].questions[${index}].description`} 
                        name={`questionGroups[${groupIndex}].questions[${index}].description`} 
                        className="textarea textarea-bordered h-24" />
                    {context.touched.description && context.errors.description && <div className="inputError">{context.errors.description}</div>}

                </div>
                <div className="form-control w-full max-w-xs">
                    <h4 className="font-semibold text-base mb-3">Outcomes</h4>

                    <label htmlFor={`questionGroups[${groupIndex}].questions[${index}].type`} className="label">
                        <span className="label-text">Outcome type</span>
                    </label>
                    <Field name={`questionGroups[${groupIndex}].questions[${index}].type`}>
                        {({ field, form }: FieldProps) => (
                            <select
                                id={`questionGroups[${groupIndex}].questions[${index}].type`}
                                className="select select-bordered w-full max-w-xs"
                                onChange={e => changeType(e, field, form.values)}
                                value={question.type}
                            >
                                <option value={QuestionType.BOOLEAN}>{QuestionType.BOOLEAN}</option>
                                <option value={QuestionType.NUMBER}>{QuestionType.NUMBER}</option>
                                <option value={QuestionType.NUM_RANGE}>{QuestionType.NUM_RANGE}</option>
                                <option value={QuestionType.STRING}>{QuestionType.STRING}</option>
                            </select>
                        )}
                    </Field>
                    <span>
                        { /* JSON.stringify(context.values.questions[index])*/}
                    </span>
                    {question.type === QuestionType.BOOLEAN && (
                        <BooleanOutcomeForm questionIndex={index} groupIndex={groupIndex} question={question}/>
                    )}
                    {question.type === QuestionType.NUMBER && (
                        <NumericOutcomeForm questionIndex={index} groupIndex={groupIndex} question={question} />
                    )}
                    {question.type === QuestionType.NUM_RANGE && (
                        <RangeOutcomeForm questionIndex={index} groupIndex={groupIndex} question={question}/>
                    )}
                    {question.type === QuestionType.STRING && (
                        <TextOutcomeForm questionIndex={index} groupIndex={groupIndex} question={question} />
                    )}
                </div>
            </div>
        </div>

    )
}