import { Field, FieldInputProps, FieldProps, useFormikContext } from "formik";
import * as React from "react";
import { BooleanOutcome, HealthcheckModel, Question } from "../../../../models/HealthcheckModel";

export const BooleanOutcomeForm = ({questionIndex, groupIndex, question} : {questionIndex: number, groupIndex: number, question: Question}) => {
    const context = useFormikContext<HealthcheckModel>();

    function recast(e: React.ChangeEvent<any>, field: FieldInputProps<HealthcheckModel>, values: HealthcheckModel) {
        !question.outcomes[0] && (question.outcomes[0] = new BooleanOutcome(100, true));
        var outcome: BooleanOutcome = question.outcomes[0];
        
        outcome.expectedResult = e.target.value === "true";

        question.outcomes[0] = outcome;

        context.setValues(values)
    }

    return (
        <div className="form-control w-full max-w-xs">
            <label htmlFor={`questionGroups[${groupIndex}].questions[${questionIndex}].outcomes[0].expectedResult`} className="label">
                <span className="label-text">Expected outcome</span>
            </label>
            <Field name={`questionGroups[${groupIndex}].questions[${questionIndex}].outcomes[0].expectedResult`}>
                {({ field, form }: FieldProps) => (
                        <select 
                            id={`questionGroups[${groupIndex}].questions[${questionIndex}].outcomes[0].expectedResult`} 
                            className="select select-success w-full max-w-xs"
                                onChange={e => recast(e, field, form.values)}
                                value={(question.outcomes[0] as BooleanOutcome).expectedResult+""}>
                                <option value="true">True</option>
                                <option value="false">False</option>
                        </select>
                    )
                }
            </Field>
        </div>
    )

} 