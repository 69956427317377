import { Field, FieldProps, useFormikContext } from "formik";
import * as React from "react";
import { Dispatch, SetStateAction, useState } from "react";
import { StringOutcome } from "../../../models/HealthcheckModel";
import { HealthcheckResultModel, QuestionResult } from "../../../models/HealthcheckResultModel";

export const TextQuestion = ({ questionResult, groupIndex, questionIndex, outcomeSetter }:
    { questionResult: QuestionResult, groupIndex: number, questionIndex: number, outcomeSetter: Dispatch<SetStateAction<boolean | null>> }) => {
    const context = useFormikContext<HealthcheckResultModel>();

    const [successOutcome, setSuccessOutcome] = useState<boolean | null>(null);

    function evaluate(e: React.ChangeEvent<any>, values: HealthcheckResultModel) {
        const value = (e.target.value === "" ? null : e.target.value)

        const tempOutcome: StringOutcome = questionResult.outcomes[0]
        const outcome: StringOutcome = new StringOutcome(tempOutcome.successPercentage, tempOutcome.expectedResult!)

        questionResult.answer = value

        const success = outcome.Validate(value)

        questionResult.result = success ? questionResult.outcomes[0].successPercentage : 0;
        context.setValues(values)

        if (value !== null) {
            outcomeSetter(success)
            setSuccessOutcome(success)
        } else {
            outcomeSetter(null)
            setSuccessOutcome(null)
        }
    }

    return (
        <div className="form-control w-full max-w-xs">
            <label htmlFor={`resultGroups[${groupIndex}].results[${questionIndex}].answer`} className="label">
                <span className="label-text">Measured value</span>
            </label>
            <Field name={`resultGroups[${groupIndex}].results[${questionIndex}].answer`}>
                {({ field, form }: FieldProps) => (
                    <input
                        type="text"
                        id={`resultGroups[${groupIndex}].results[${questionIndex}].answer`}
                        className={`input input-bordered ${successOutcome !== null && (successOutcome ? "input-success" : "input-error")} w-full max-w-xs`}
                        onChange={e => evaluate(e, form.values)}
                        value={questionResult.answer as string}
                    />
                )
                }
            </Field>
            <span className="text-sm mt-4">
                Expected value: <span className="font-bold">{(questionResult.outcomes[0] as StringOutcome).expectedResult}</span>
            </span>
        </div>
    )

} 