import * as React from "react";
import { useEffect } from "react";
import { fetchHealthcheckResults } from "../../lib/HealthcheckResultApi";
import { HealthcheckResultListModel } from "../../models/HealthcheckResultModel";
import HealthcheckResultListItem from "../healthcheck-result/HealthcheckResultListItem";

export const LatestResults = () => {

    const [healthcheckResults, setHealthcheckResults] = React.useState<HealthcheckResultListModel[]>([]);

    useEffect(() => {
        fetchHealthcheckResults(0, 10).then(data => setHealthcheckResults(data))
    }, []);

    return (
        <>
            <div className="md:col-span-1">
                <div className="px-2 sm:px-0">
                    <h3 className="text-xl font-medium leading-6 text-gray-900">Latest results</h3>
                    <p className="mt-1 text-sm text-gray-600">
                        Overview of latest healtcheck results
                    </p>
                </div>
            </div>
            <div className="mt-5 md:col-span-3 md:mt-0 mb-4">
                <div
                    className={`p-2 border flex-grow grid gap-2 ${healthcheckResults.length ? "auto-rows-min" : ""
                        } grid-cols-1`}
                >
                    {healthcheckResults.length ? (
                        healthcheckResults.map((healthcheckResult) => (
                            <HealthcheckResultListItem
                                key={healthcheckResult.id}
                                healthcheckResult={healthcheckResult}
                                onDelete={() => { }}
                                showActionButtons={false}
                            />
                        ))
                    ) : (
                        <span
                            className={
                                "h-full flex justify-center items-center"
                            }
                        >
                            You do have any healthchecks yet!
                        </span>
                    )}
                </div>
            </div>
        </>
    )

}