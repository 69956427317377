import * as React from "react";
import { HealthcheckResultModel, QuestionResultLevels } from "../../../../models/HealthcheckResultModel";
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';


export const OverallPieChart = ({ healthcheckResult }: { healthcheckResult: HealthcheckResultModel }) => {
  const successNum = healthcheckResult.resultGroups.flatMap(group => group.results).filter((res) => res.result === QuestionResultLevels.success).length
  const failNum = healthcheckResult.resultGroups.flatMap(group => group.results).filter((res) => res.result === QuestionResultLevels.fail).length
  const naNum = healthcheckResult.resultGroups.flatMap(group => group.results).filter((res) => res.result === undefined).length

  const data = {
    labels: ['Success', 'Fail', 'N/A'],
    datasets: [
      {
        label: 'Results',
        data: [
          successNum,
          failNum,
          naNum
        ],
        backgroundColor: [
          'rgba(54, 211, 153, 1)',
          'rgba(248, 114, 114, 1)',
          'rgba(229, 231, 235, 1)'
        ]
      },
    ],
  };
  const options = {
    maintainAspectRatio: true,
    legend: {
      display: false,
    },
    plugins: {
      title: {
        display: true,
        text: 'Overall results',
      },
    }
  }

  return (
    <Doughnut data={data} options={options} />
  )

}