import * as React from "react";
import { NavLink } from "react-router-dom";
import { ResultGroup } from "../../../models/HealthcheckResultModel";

export const GroupSteps = ({ resultGroups, currentIndex, nextGroupUrl }: { resultGroups: ResultGroup[], currentIndex: number, nextGroupUrl: string }) => {

    return (
        <div className="bg-white rounded-lg mb-4 p-6 border">
            <ul className="steps w-full">
                {resultGroups.map((resultGroup, groupIndex) => (
                    <li className={`step ${groupIndex <= currentIndex - 1 ? "step-primary" : ""}`} title={resultGroup.description}>
                        <NavLink
                            to={`${nextGroupUrl}/${groupIndex+1}`}>
                            {resultGroup.title}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    )

}