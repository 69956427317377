export interface HealthcheckModel {
    id?: number;
    hcId?: string;
    title: string;
    description: string;
    questionGroups: QuestionGroup[];
}

export interface QuestionGroup {
    title: string;
    description: string;
    questions: Question[];
}

export interface Question {
    id: string;
    title: string;
    description: string;
    type: QuestionType;
    outcomes: Outcome[];
}

export enum QuestionType {
    BOOLEAN = "True/False",
    NUMBER = "Number",
    NUM_RANGE = "Numeric range", 
    STRING = "Text",
}

export interface Outcome {
    successPercentage: number;
    Validate(value: any): boolean;
}

export class BooleanOutcome implements Outcome {
    successPercentage!: number;
    expectedResult?: boolean;
    
    constructor(successPercentage: number, expectedResult: boolean) {
        this.successPercentage = successPercentage;
        this.expectedResult = expectedResult;
    }

    Validate(value: any): boolean {
        return typeof value == "boolean" && value === this.expectedResult;
    }
}

export class NumberOutcome implements Outcome {
    successPercentage!: number;
    expectedResult?: number;

    constructor(successPercentage: number, expectedResult: number) {
        this.successPercentage = successPercentage;
        this.expectedResult = expectedResult;
    }

    Validate(value: any): boolean {
        return typeof value == "number" && value === this.expectedResult;
    }
}

export class NumRangeOutcome implements Outcome {
    successPercentage!: number;
    expectedResult?: NumRange = new NumRange(0, 100);

    constructor(successPercentage: number, expectedResult: NumRange) {
        this.successPercentage = successPercentage;
        this.expectedResult = expectedResult;
    }

    Validate(value: any): boolean {
        return typeof value == "number" && value >= this.expectedResult?.from! && value <= this.expectedResult?.to! ;
    }

    isRange? = (x: any): x is NumRange => true;
}

export class StringOutcome implements Outcome {
    successPercentage!: number;
    expectedResult?: string;

    constructor(successPercentage: number, expectedResult: string) {
        this.successPercentage = successPercentage;
        this.expectedResult = expectedResult;
    }

    Validate(value: any): boolean {
        return typeof value == "string" && this.expectedResult?.toLowerCase().replace(/\s+/g, '') === value.toLowerCase().replace(/\s+/g, '')
    }
}

export class NumRange {
    from: number;
    to: number;

    constructor(from: number, to: number) {
        this.from = from;
        this.to = to;
    }
}