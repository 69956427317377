import * as React from "react";
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { ResultStats } from "../../../models/HealthcheckResultModel";


export const SuccessPercentagePieChart = ({resultStats}: {resultStats: ResultStats[]}) => {
  const successNum = resultStats.map(row => row.success??0).reduce((a,b) => {return a+b});
  const questionsNum = resultStats.map(row => row.questions??0).reduce((a,b) => {return a+b});
  const sPct = Math.round(successNum * 100 / questionsNum)
  const rest = 100 - sPct;

  const data = {
    labels: ['Success percentage', 'Failure percentage'],
    datasets: [
      {
        label: '%',
        data: [
          sPct,
          rest
        ],
        backgroundColor: [
          'rgba(54, 211, 153, 1)',
          'rgba(248, 114, 114, 1)',
          'rgba(229, 231, 235, 1)'
        ]
      },
    ],
  };
  const options = {
    maintainAspectRatio: true,
    legend: {
      display: false,
    },
    plugins: {
      title: {
        display: true,
        text: 'Overal success percentage',
      },
    }
  }

  return (
    <Doughnut data={data} options={options} />
  )

}