import { createClient } from "@supabase/supabase-js";
import { REACT_APP_SUPABASE_KEY, REACT_APP_SUPABASE_URL } from "./constants";
import { Database } from "./database.types";

export const supabase = createClient<Database>(
    REACT_APP_SUPABASE_URL,
    REACT_APP_SUPABASE_KEY
);

export const documentPath = (userId: string, id: string) => {
    return `${userId}/${id}.json`
}

