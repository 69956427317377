import * as React from "react";
import { HealthcheckResultModel } from "../../../models/HealthcheckResultModel";

export const HealthcheckInfo = ({ healthcheckResult }: { healthcheckResult: HealthcheckResultModel }) => {

    return (

        <div className="shadow rounded-lg bg-white">
            <div className="space-y-6 px-4 py-5 sm:p-6">
                <div className="w-full">
                    <h2 className="text-xl font-bold">{healthcheckResult.healthcheckModel?.title}</h2>
                    <div className="divider w-full"></div>
                    <span className="">
                        {healthcheckResult.healthcheckModel?.description}
                    </span>
                </div>
                <div className="w-full">
                    Performed on: <span className="font-semibold">{healthcheckResult.createdAt?.toLocaleString()}</span>
                </div>
            </div>
        </div>
    )

}