import { supabase } from "../../lib/api";
import { useRef } from "react";
import React from "react";
import { ShieldCheckIcon } from "@heroicons/react/24/outline";

const RecoverPassword = ({recoveryToken, setRecoveryToken}: {recoveryToken: string,  setRecoveryToken: React.Dispatch<React.SetStateAction<string | null>>}) => {

    const newPasswordRef = useRef<HTMLInputElement>(null);

    const handleNewPassword = async ({ token }: { token: string | null }) => {
        if (!token) return;

        const newPassword = newPasswordRef?.current?.value;
        const { error } = await supabase.auth.updateUser({
            password: newPassword,
        });

        if (!error) {
            setRecoveryToken(null)
        } else {
            console.error(error);
        }
    };

    return (
        <div className="hero min-h-screen bg-base-200">
            <div className="hero-content flex-col lg:flex-row-reverse min-w-full">
                <div className="text-center lg:text-left p-6">
                    <div className="text-5xl font-bold">
                        <ShieldCheckIcon className="h-12 w-12 mr-2 inline-block" aria-hidden="true" />
                        Healthchk.app
                    </div>
                    <p className="py-6">Login to access the Healthchk.app dashboard.</p>
                </div>
                <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
                    <div className="card-body">

                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">New password</span>
                            </label>
                            <input
                                type={"password"}
                                name={"password"}
                                ref={newPasswordRef}
                                required
                                placeholder="password"
                                className="input input-bordered"
                            />
                        </div>
                        <div className="form-control mt-6">
                            <div className="w-full">
                                <button
                                    className="btn btn-success w-full"
                                    type="submit"
                                    onClick={() => handleNewPassword({ token: recoveryToken })}
                                >
                                    Change Password
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default RecoverPassword;
