import { ChartBarIcon, Cog6ToothIcon, EllipsisHorizontalIcon, TrashIcon } from "@heroicons/react/24/outline";
import React from "react";
import { NavLink } from "react-router-dom";
import { HealthcheckResultListModel } from "../../models/HealthcheckResultModel";
import { ConfirmationDialog } from "../common/ConfirmationDialog";

const HealthcheckResultListItem = ({ healthcheckResult, onDelete, showActionButtons = true}: { healthcheckResult: HealthcheckResultListModel; onDelete: Function, showActionButtons?: boolean}) => {
    return (
        <div className="card w-full bg-base-100 shadow-xl">
            {showActionButtons && (
                <ConfirmationDialog
                    modalId={`confirmDelete-${healthcheckResult.id}`}
                    titleText={"Do you want to permanently delete this result?"}
                    bodyText={"This action is irreversible."}
                    confirmBtn={
                        <label onClick={() => onDelete(healthcheckResult)} className="btn btn-error ml-4">
                            <TrashIcon className="h-6 w-6" />
                            Delete
                        </label>
                    }
                />
            )}
            <div className="card-body">
                <div className="flex">
                    <div className="w-full">
                        <NavLink to={`/healthcheck-results/${healthcheckResult.id}`}>
                            <h2 className="card-title">
                                {healthcheckResult.healthcheckModel?.title} - {healthcheckResult.subject?.target?.name}
                            </h2>
                        </NavLink>
                    </div>
                    {showActionButtons && (
                        <div className="dropdown dropdown-end">
                            <label tabIndex={0} className="btn btn-outline text-gray-900 h-2">
                                <EllipsisHorizontalIcon className="h-6 w-6" />
                            </label>
                            <ul tabIndex={0} className="dropdown-content menu bg-base-100 rounded-box w-32 shadow-lg border">
                                <li>
                                    <NavLink
                                        to={`/healthcheck-results/${healthcheckResult.id}/edit`}
                                    >
                                        <Cog6ToothIcon className="h-6 w-6" />
                                        Edit
                                    </NavLink>
                                </li>
                                <li className="bg-error text-white">
                                    <label htmlFor={`confirmDelete-${healthcheckResult.id}`} className="btn btn-error text-right text-white">
                                        <TrashIcon className="h-6 w-6" />
                                        Delete
                                    </label>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-5">

                    <div className="mt-4 lg:col-span-2">
                            <p>
                                Organization: <span className="font-semibold">{healthcheckResult.subject?.organization?.name}</span>
                            </p>
                            <p>Unit: <span className="font-semibold">{healthcheckResult.subject?.unit?.name}</span></p>
                        
                    </div>
                    <div className="lg:col-span-3">
                        <div className="stats shadow stats-vertical lg:stats-horizontal w-full">

                            <div className="stat">
                                <div className="stat-figure text-success">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path></svg>
                                </div>
                                <div className="stat-title">Success percentage</div>
                                <div className="stat-value text-success">
                                    {Math.round(healthcheckResult.resultStats?.success! * 100 / healthcheckResult.resultStats?.questions!)}%
                                </div>
                                <div className="stat-desc">Of all questions</div>
                            </div>

                            <div className="stat">
                                <div className="stat-figure text-primary">
                                    <ChartBarIcon className="inline-block w-8 h-8 stroke-current"/>
                                </div>
                                <div className="stat-title">Success / Fail / N/A</div>
                                <div className="stat-value text-primary">
                                    <span className="text-success">{healthcheckResult.resultStats?.success!}</span>
                                    <span className="text-gray-300 ml-2 mr-2">/</span> 
                                    <span className="text-error">{healthcheckResult.resultStats?.fail!}</span>
                                    <span className="text-gray-300 ml-2 mr-2">/</span>  
                                    <span className="text-gray-300">{healthcheckResult.resultStats?.unavailable}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HealthcheckResultListItem;
