export const Navigation = [
  { name: 'Dashboard', href: '/'},
  { name: 'Healthchecks', href: '/healthchecks'},
  { name: 'Healthcheck results', href: '/healthcheck-results'},
  { name: 'Documentation', href: '/docs'},
]

export const UserNavigation = [
  { name: 'Your Profile', href: '/profile' },
]

export function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}