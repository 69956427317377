import { Cog6ToothIcon, EllipsisHorizontalIcon, TrashIcon } from "@heroicons/react/24/outline";
import React from "react";
import { NavLink } from "react-router-dom";
import { HealthcheckModel } from "../../models/HealthcheckModel";

const HealthcheckListItem = ({ healthcheck, onDelete }: { healthcheck: HealthcheckModel; onDelete: Function }) => {
    
    return (
        <div className="card w-full bg-white outline-accent shadow-xl mb-6">
            <div className="card-body">
                <div className="flex">
                    <h2 className="card-title text-gray-900 w-full">{ healthcheck.title }</h2>
                    <div className="dropdown dropdown-end">
                        <label tabIndex={0} className="btn btn-outline text-gray-900 h-2">
                            <EllipsisHorizontalIcon className="h-6 w-6"/>
                        </label>
                        <ul tabIndex={0} className="dropdown-content menu bg-base-100 rounded-box w-32 shadow-lg border">
                            <li>
                                <NavLink
                                    to={`/healthchecks/${healthcheck.hcId}/edit`}
                                >
                                    <Cog6ToothIcon className="h-6 w-6"/>
                                    Edit
                                </NavLink>
                            </li>
                            <li className="bg-error text-white">
                                <button
                                    onClick={() => onDelete(healthcheck)}
                                >
                                    <TrashIcon className="h-6 w-6"/>
                                    Delete
                                </button>
                            </li>
                        </ul>
                    </div> 
                </div>
                <p>{ healthcheck.description }</p>
                <div className="card-actions justify-end">
                    <NavLink to={`/healthchecks/${healthcheck.hcId}/evaluate`} className="btn btn-primary">
                        Start
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default HealthcheckListItem;
