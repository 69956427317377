import * as React from "react";
import { HealthcheckResultModel } from "../../../models/HealthcheckResultModel";

export const SubjectInfo = ({ healthcheckResult }: { healthcheckResult: HealthcheckResultModel }) => {

    return (

        <div className="shadow rounded-lg bg-white">
            <div className="space-y-6 px-4 py-5 sm:p-6">
                <div className="w-full">
                    Organization: <span className="font-semibold">{healthcheckResult.subject.organization?.name}</span>
                    <div className="divider"></div>
                    Unit: <span className="font-semibold">{healthcheckResult.subject.unit?.name}</span>
                    <div className="divider"></div>
                    Target: <span className="font-semibold">{healthcheckResult.subject.target?.name}</span>
                </div>
            </div>
        </div>
    )

}