import { Field, useFormikContext } from "formik";
import * as React from "react";
import { HealthcheckResultModel } from "../../../models/HealthcheckResultModel";

export const SubjectInfoForm = () => {
    const context = useFormikContext<HealthcheckResultModel>();

    return (
        <div className="bg-white rounded-lg p-6">
            <div className="grid grid-cols-1">
                <div className="form-control w-full max-w-xs">

                    <label htmlFor="subject.organization.name" className="label font-semibold">
                        <span className="label-text">Organization</span>
                    </label>
                    <Field
                        type="text"
                        value={context.values.subject.organization?.name}
                        placeholder="Organization"
                        id="organization"
                        name="subject.organization.name"
                        className="input input-bordered w-full max-w-xs"
                    />

                    <label htmlFor="subject.unit.name" className="label font-semibold">
                        <span className="label-text">Unit</span>
                    </label>
                    <Field
                        type="text"
                        value={context.values.subject.unit?.name}
                        placeholder="Unit"
                        id="unit"
                        name="subject.unit.name"
                        className="input input-bordered w-full max-w-xs"
                    />

                    <label htmlFor="subject.target.name" className="label font-semibold">
                        <span className="label-text">Target</span>
                    </label>
                    <Field
                        type="text"
                        value={context.values.subject.target?.name}
                        placeholder="Target"
                        id="target"
                        name="subject.target.name"
                        className="input input-bordered w-full max-w-xs"
                    />
                </div>
            </div>
        </div>
    )

}