
import { supabase } from "./api";
import { User } from "@supabase/supabase-js";
import { ExtendedUser } from "../models/UserModel";

export const fetchExtendedUser = async (user: User) => {
    let { data: extendedUser, error } = await supabase
        .from("hcapp_user_ext")
        .select("*")
        .eq("id", user.id)
        .single()
    if (error) {
        console.log("error", error);
        throw error
    }
    return {
        name: extendedUser?.name,
        ...user
    } as ExtendedUser
};

export const saveExtendedUser = async (user: ExtendedUser) => {
    let { error: upsertError } = await supabase
        .from("hcapp_user_ext")
        .upsert({ id: user.id, name:  user.name})
        .eq("id", user.id)
        .single();
    
    if (upsertError) return upsertError
}

export const fetchOrganisation = async (id: string) => {

}