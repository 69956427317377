import * as React from "react";
import { ResultGroup } from "../../../models/HealthcheckResultModel";
import { SingleQuestionForm } from "./SingleQuestionForm";

export const QuestionResultsForm = ({resultGroup, groupIndex}: {resultGroup: ResultGroup, groupIndex: number}) => {
    var questionResult = resultGroup.results
    return (
        <>
            {questionResult ? (
                questionResult.map((questionResult, questionIndex) => (
                    <SingleQuestionForm
                        key={questionResult.id}
                        groupIndex={groupIndex}
                        questionIndex={questionIndex}
                        questionResult={questionResult}
                    />
                ))
            ) : (
                <span
                    className={
                        "h-full flex justify-center items-center"
                    }
                >
                    No questions defined in Healthcheck
                </span>
            )}
        </>
    )

}