
import { User } from "@supabase/supabase-js";
import { v4 } from "uuid";
import { HealthcheckModel } from "../models/HealthcheckModel";
import { documentPath, supabase } from "./api";

export const fetchHealthchecks = async (from: number, to: number) => {
    let { data: healthchecks, error } = await supabase
        .from("healthchecks")
        .select("*")
        .order("created_at", { ascending: false })
        .range(from, to);
    if (error) {
        console.log("error", error);
        throw error
    }
    var modifiedHealthchecks = healthchecks?.map(healthcheck => {
        return {...healthcheck, questionGroups: []}
    })
    return modifiedHealthchecks as HealthcheckModel[]
};

export const countHealthchecks = async () => {
    let { error, count } = await supabase
        .from("healthchecks")
        .select("*", {head: true, count: "exact"})
    if (error) {
        console.log("error", error);
        throw error
    }
    return count
};

export const saveHealthcheck = async (values: HealthcheckModel, user: User) => {
    values.hcId = values.hcId ?? v4()

    // data: uploaData , 
    let { error: uploadError } = await supabase.storage
        .from("healthchecks")
        .upload(documentPath(user.id, values.hcId), JSON.stringify(values), { upsert: true })

    if (uploadError) return uploadError

    // data: healthcheck, 
    let { error: upsertError } = await supabase
        .from("healthchecks")
        .upsert({ title: values.title, description: values.description, user_id: user.id, hcId: values.hcId })
        .single();

    if (upsertError) return upsertError
}

export const loadHealthcheck = async (hcId: string, user: User) => {
    let { data, error } = await supabase.storage
        .from("healthchecks")
        .download(documentPath(user.id, hcId))

    if (error) {
        console.log("error", error)
        throw Error("Failed to load");
    }

    var jsonData = await data?.text()
    var hc = JSON.parse(jsonData!)

    return hc as HealthcheckModel
}

export const deleteHealthcheck = async (hcId: string, user: User) => {
    let { error } = await supabase.storage.from("healthchecks").remove([documentPath(user.id, hcId)])
    if (error) return error

    let { error: dbError } = await supabase.from("healthchecks").delete().eq("hcId", hcId)
    if (dbError) return dbError

    return true
}
