import * as React from "react";
import { ResultGroup } from "../../../models/HealthcheckResultModel";
import { QuestionResultItem } from "./QuestionResultItem";

export const ResultGroupItem = ({ group }: { group: ResultGroup }) => {

    return (
        <div className="grid grid-cols-1 p-4 border-4 border-dashed border-gray-300 rounded mb-4">
            <div className="flex mb-2">
                <div className="w-full">
                    <h2 className="card-title">{group.title}</h2>
                    <span>{group.description}</span>
                </div>
            </div>

            {group.results!.map((result, i) => (
                <QuestionResultItem questionIndex={i} questionResult={result} />
            ))}

        </div>

    )

}