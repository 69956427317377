import { User } from "@supabase/supabase-js";
import * as React from "react";
import { Header } from "../common/Header";
import { LatestResults } from "./LatestResults";
import { PerUserStats } from "./PerUserStats";

export const Dashboard = ({ user }: { user: User }) => {

    return (
        <div>
            <Header title="Dashboard" />
            <main>
                <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                    <div className="md:grid md:grid-cols-4 md:gap-2">
                        <LatestResults />
                        <PerUserStats user={user} />
                    </div>
                </div>
            </main>
        </div>
    )

}