import { User } from "@supabase/supabase-js";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { loadHealtcheckResult } from "../../lib/HealthcheckResultApi";
import { HealthcheckResultModel } from "../../models/HealthcheckResultModel";
import { Header } from "../common/Header";
import { HealthcheckInfo } from "./components/HealthcheckInfo";
import { OverviewCharts } from "./components/OverviewCharts";
import { ResultGroupItem } from "./components/ResultGroupItem";
import { SubjectInfo } from "./components/SubjectInfo";

export const HealthcheckResult = ({ user }: { user: User }) => {

    const [healthcheckResult, setHealthcheckResult] = useState<HealthcheckResultModel>();

    let { hcResultId } = useParams();

    useEffect((localHcResultId: string | undefined = hcResultId, localUser: User = user) => {
        const fetchHealthcheckResult = async () => {
            if (localHcResultId && !healthcheckResult) {
                const healthcheck = await loadHealtcheckResult(undefined, localHcResultId, localUser);
                setHealthcheckResult(healthcheck)
            }
        };
        fetchHealthcheckResult().catch(error => { throw (error) })
    }, [hcResultId, user, healthcheckResult]);

    return (
        <>
            <Header title="Healthcheck result" buttons={[
                <>
                    <NavLink key="cancel" to='/healthcheck-results' className='btn btn-ghost mr-4'>
                        Back to results
                    </NavLink>
                    <NavLink key="edit" to={`/healthcheck-results/${hcResultId}/edit`} className='btn btn-accent mr-4'>
                        Edit
                    </NavLink>
                </>
            ]} />
            <main>
                <>

                    <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                        {healthcheckResult ? (
                            <div className="md:grid md:grid-cols-3 md:gap-6">
                                <div className="md:col-span-1">
                                    <div className="px-4 sm:px-0">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Healthcheck</h3>
                                        <p className="mt-1 text-sm text-gray-600">
                                            Information about performed healthcheck
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-5 md:col-span-2 md:mt-0">
                                    <HealthcheckInfo healthcheckResult={healthcheckResult!} />
                                </div>

                                <div className="md:col-span-1">
                                    <div className="px-4 sm:px-0">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Subject</h3>
                                        <p className="mt-1 text-sm text-gray-600">
                                            Information about evaluated subject
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-5 md:col-span-2 md:mt-0">
                                    <SubjectInfo healthcheckResult={healthcheckResult!} />
                                </div>

                                <div className="md:col-span-1">
                                    <div className="px-4 sm:px-0">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Result statistics</h3>
                                        <p className="mt-1 text-sm text-gray-600">
                                            Statistics about evaluated healthcheck results
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-5 md:col-span-2 md:mt-0">
                                    <OverviewCharts healthcheckResult={healthcheckResult!} />
                                </div>

                                <div className="md:col-span-1">
                                    <div className="px-4 sm:px-0">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Healthcheck questions</h3>
                                        <p className="mt-1 text-sm text-gray-600">
                                            Evaluated healthcheck results
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-5 md:col-span-2 md:mt-0">
                                    {healthcheckResult.resultGroups.map((resultGroup, index) => (
                                        <ResultGroupItem group={resultGroup} />
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className=" bg-white px-4 py-5 text-center">
                                Loading...
                            </div>
                        )}
                    </div>

                    <div className="bg-white">
                        <div className="flex mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
                            <div className="w-full mr-4"></div>
                            <NavLink to='/healthcheck-results' className='btn btn-ghost mr-4'>
                                Back to results
                            </NavLink>
                        </div>
                    </div>
                </>
            </main>
        </>
    );
};
