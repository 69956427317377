import * as React from "react";
import { HealthcheckResultModel, QuestionResultLevels } from "../../../../models/HealthcheckResultModel";
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';

export const mapResultToLabel = (result: number) => {
  switch (result) {
    case 100: return 100;
    case 0: return -100;
    default: return 0;
  }
}

export const PerQuestionBarChart = ({ healthcheckResult }: { healthcheckResult: HealthcheckResultModel }) => {

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Results per question',
      },
    },
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const data = {
    labels: healthcheckResult.resultGroups.flatMap(group => group.results).map((res) => res.title),
    datasets: [
      {
        label: 'Success',
        data: healthcheckResult.resultGroups.flatMap(group => group.results).map((res) => (res.result === QuestionResultLevels.success ? 100 : 0)),
        backgroundColor: "rgba(54, 211, 153, 1)",
      },
      {
        label: 'Fail',
        data: healthcheckResult.resultGroups.flatMap(group => group.results).map((res) => (res.result === QuestionResultLevels.fail ? 100 * -1 : 0)),
        backgroundColor: "rgba(248, 114, 114, 1)",
      },
    ],
  };

  return (
    <Bar options={options} data={data} />
  )

}