import * as React from "react";
import { HealthcheckResultModel, QuestionResultLevels } from "../../../../models/HealthcheckResultModel";
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';


export const PerGroupDonutChart = ({ healthcheckResult }: { healthcheckResult: HealthcheckResultModel }) => {
    const groups = healthcheckResult.resultGroups.map(group => group.title)
    const groupsData = healthcheckResult.resultGroups.map(group => group.questions.length)

    const resultsLabels = healthcheckResult.resultGroups.map(_ => ['Success', 'Fail', 'N/A']).flatMap(l => l)
    const resultsData = healthcheckResult.resultGroups.map(g => {
        return [
            g.results.filter((res) => res.result === QuestionResultLevels.success).length,
            g.results.filter((res) => res.result === QuestionResultLevels.fail).length,
            g.results.filter((res) => res.result === undefined).length
        ]
    }).flatMap(d => d)

    const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);

    const data = {
        datasets: [
            {
                label: 'Group',
                labels: groups,
                data: groupsData,
                backgroundColor: groups.map(_ => `rgba(${randomNum()}, ${randomNum()}, ${randomNum()}, 1)`)
            },
            {
                label: 'Results',
                labels: resultsLabels,
                data: resultsData,
                backgroundColor: [
                    'rgba(54, 211, 153, 1)',
                    'rgba(248, 114, 114, 1)',
                    'rgba(229, 231, 235, 1)'
                ]
            },
        ],
    };

    const options = {
        title: "Abc",
        maintainAspectRatio: true,
        legend: {
            display: false,
        },
        plugins: {
            title: {
                display: true,
                text: 'Results per group',
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: (tooltipItem: any) => {
                        var dataIndex = tooltipItem.dataIndex;
                        return (tooltipItem.dataset.labels[dataIndex] + ": " + tooltipItem.dataset.data[dataIndex]
                        );
                    }
                }
            }
        }
    }

    return (
        <Doughnut data={data} options={options} />
    )

}