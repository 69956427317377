import * as React from "react";
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { ResultStats } from "../../../models/HealthcheckResultModel";


export const OverallPieChart = ({resultStats}: {resultStats: ResultStats[]}) => {
  const successNum = resultStats.map(row => row.success??0).reduce((a,b) => {return a+b});
  const failNum = resultStats.map(row => row.fail??0).reduce((a,b) => {return a+b});
  const naNum = resultStats.map(row => row.unavailable??0).reduce((a,b) => {return a+b});

  const data = {
    labels: ['Success', 'Fail', 'N/A'],
    datasets: [
      {
        label: 'Results',
        data: [
          successNum,
          failNum,
          naNum
        ],
        backgroundColor: [
          'rgba(54, 211, 153, 1)',
          'rgba(248, 114, 114, 1)',
          'rgba(229, 231, 235, 1)'
        ]
      },
    ],
  };
  const options = {
    maintainAspectRatio: true,
    legend: {
      display: false,
    },
    plugins: {
      title: {
        display: true,
        text: 'Overall results',
      },
    }
  }

  return (
    <Doughnut data={data} options={options} />
  )

}