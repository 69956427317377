import { ReactNode } from 'react'

export const ConfirmationDialog = ({ modalId, titleText, bodyText, confirmBtn }: { modalId: string, titleText: string, bodyText: string, confirmBtn: ReactNode }) => {
    return (
        <>
            <input type="checkbox" id={modalId} className="modal-toggle" />
            <div className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">{titleText}</h3>
                    <p className="py-4">{bodyText}</p>
                    <div className="modal-action">
                        {confirmBtn}
                        <label htmlFor={modalId} className="btn ml-4">Cancel</label>
                    </div>
                </div>
            </div>
        </>
    )
}
