import type { User } from "@supabase/supabase-js";
import React from "react";
import { useEffect, useState } from "react";
import { deleteHealthcheckResult, fetchHealthcheckResults } from "../../lib/HealthcheckResultApi";
import { HealthcheckResultListModel } from "../../models/HealthcheckResultModel";
import { Header } from "../common/Header";
import HealthcheckResultListItem from "./HealthcheckResultListItem";

const PER_PAGE = 10;

const HealthchecksOverview = ({ user }: { user: User }) => {
    const [pageNumber] = useState<number>(0);
    const [healthcheckResults, setHealthcheckResults] = useState<HealthcheckResultListModel[]>([]);

    useEffect(() => {
        fetchHealthcheckResults(pageNumber*PER_PAGE,(pageNumber+1)*PER_PAGE).then(data => {
            console.log(data); // @TODO remove
            setHealthcheckResults(data)
        })
    }, [pageNumber]);

    const deleteHealthcheckEvent = async (id: string) => {
        deleteHealthcheckResult(id, user).then(_ => setHealthcheckResults(healthcheckResults.filter((x) => x.id! !== id)))
    };

    return (
        <div>
            <Header title="Healthcheck results" buttons={[]} />
            <main>
                <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                    <div
                        className={"flex flex-col flex-grow p-4"}
                    >
                        <div
                            className={`p-2 border flex-grow grid gap-2 ${healthcheckResults.length ? "auto-rows-min" : ""
                                } grid-cols-1`}
                        >
                            {healthcheckResults.length ? (
                                healthcheckResults.map((healthcheckResult) => (
                                    <HealthcheckResultListItem
                                        key={healthcheckResult.id}
                                        healthcheckResult={healthcheckResult}
                                        onDelete={() => deleteHealthcheckEvent(healthcheckResult.id!)}
                                    />
                                ))
                            ) : (
                                <span
                                    className={
                                        "h-full flex justify-center items-center"
                                    }
                                >
                                    Loading healthcheck results...
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default HealthchecksOverview;
