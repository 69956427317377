import React from "react";
import { ExtendedUser } from "../../models/UserModel";
import { Header } from "../common/Header";

const Documentation = ({ user }: { user: ExtendedUser }) => {
    return (
        <div>
            <Header title="Documentation" buttons={[]} />
            <main>
                <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                    <div
                        className={"flex flex-col flex-grow p-4"}
                    >
                        The application documentation will be there.
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Documentation;
