import { Field, Form, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { ExtendedUser } from "../../../models/UserModel";
import { Header } from "../../common/Header";
import { saveExtendedUser } from "../../../lib/UserApi";

export const Profile = ({ user }: { user: ExtendedUser }) => {

    const [successDisplayed, setSuccessDisplayed] = useState<Boolean>(false);
    const [errorDisplayed, setErrorDisplayed] = useState<Boolean>(false);
    
    const saveUser = async (values: ExtendedUser, actions: FormikHelpers<ExtendedUser>) => {
        setErrorDisplayed(false)
        setSuccessDisplayed(false)
        
        actions.setSubmitting(true)

        saveExtendedUser(values).then((err) => {
            if (err) setErrorDisplayed(true)
            else setSuccessDisplayed(true)
            actions.setSubmitting(false)
        })
    };

    return (
        <>
            <Header title="User profile" buttons={[]} />
            <main>
                <Formik
                    onSubmit={saveUser}
                    enableReinitialize={true}
                    initialValues={user}
                    >
                    <Form>
                        {!!successDisplayed && (
                            <div className="alert alert-success shadow-lg">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                                    <span>Profile updated</span>
                                </div>
                            </div>)}
                        {!!errorDisplayed && (
                            <div className="alert alert-error shadow-lg">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                                    <span>Failed to update profile.</span>
                                </div>
                            </div>)}

                        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                            <div className="md:grid md:grid-cols-3 md:gap-6">
                                <div className="md:col-span-1">
                                    <div className="px-4 sm:px-0">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">User info</h3>
                                        <p className="mt-1 text-sm text-gray-600">
                                            Information about you
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-5 md:col-span-2 md:mt-0">
                                    <div className="card w-full bg-base-100 shadow-xl">
                                        <div className="card-body">
                                            <div className="flex">
                                                <div className="w-full">
                                                    <label htmlFor="email" className="label font-semibold">
                                                        <span className="label-text">E-mail</span>
                                                    </label>
                                                    <Field type="text" placeholder="E-mail" id="email" name="email" className="input input-bordered w-full max-w-xs" disabled={true}/>

                                                    <label htmlFor="name" className="label font-semibold">
                                                        <span className="label-text">Name</span>
                                                    </label>
                                                    <Field type="text" placeholder="Name" id="name" name="name" className="input input-bordered w-full max-w-xs" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="md:col-span-1">
                                    <div className="px-4 sm:px-0">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Organisation</h3>
                                        <p className="mt-1 text-sm text-gray-600">
                                            Organisation you are assigned to
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-5 md:col-span-2 md:mt-0">
                                    <div className="card w-full bg-base-100 shadow-xl">
                                        <div className="card-body">
                                            <div className="flex">
                                                <div className="w-full">
                                                    No organisation
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white">
                            <div className="flex mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
                                <div className="w-full mr-4"></div>
                                <NavLink to='/healthchecks' className='btn btn-ghost mr-4'>
                                    Cancel
                                </NavLink>
                                <button type="submit" className='btn btn-accent'>
                                    Save
                                </button>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </main>
        </>
    );
};
