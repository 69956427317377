import { User } from "@supabase/supabase-js";
import * as React from "react";
import { useEffect } from "react";
import { fetchPerUserStatistics } from "../../lib/HealthcheckResultApi";
import { ResultStats } from "../../models/HealthcheckResultModel";
import { OverallPieChart } from "./charts/OverallPieChart";
import { SuccessPercentagePieChart } from "./charts/SuccessPercentagePieChart";

export const PerUserStats = ({ user }: { user: User }) => {

    const [stats, setStats] = React.useState<ResultStats[]>();

    useEffect(() => {
        fetchPerUserStatistics(user).then(data => {
            setStats(data)
        })
    }, [user]);

    return (
        <>
            <div className="md:col-span-1">
                <div className="px-2 sm:px-0">
                    <h3 className="text-xl font-medium leading-6 text-gray-900">Statistics</h3>
                    <p className="mt-1 text-sm text-gray-600">
                        Stats from 10 latest healtcheck results
                    </p>
                </div>
            </div>
            <div className="mt-5 md:col-span-3 md:mt-0">
                <div
                    className={`p-2 border flex-grow grid gap-2 auto-rows-min`}
                >
                    <div className="card w-full bg-base-100 shadow-xl">
                        <div className="card-body">
                            <div className="flex">
                                <div className="w-full">
                                    {stats ? (
                                        <OverallPieChart resultStats={stats} />
                                    ) : (
                                        <span>Loading...</span>
                                    )}
                                </div>
                                <div className="w-full">
                                    {stats ? (
                                        <SuccessPercentagePieChart resultStats={stats} />
                                    ) : (
                                        <span>Loading...</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}