import { Field, FieldInputProps, FieldProps, useFormikContext } from "formik";
import * as React from "react";
import { HealthcheckModel, NumberOutcome, Question } from "../../../../models/HealthcheckModel";

export const NumericOutcomeForm = ({questionIndex, groupIndex, question} : {questionIndex: number, groupIndex: number, question: Question}) => {
    const context = useFormikContext<HealthcheckModel>();

    function recast(e: React.ChangeEvent<any>, field: FieldInputProps<HealthcheckModel>, values: HealthcheckModel) {
        !question.outcomes[0] && (question.outcomes[0] = new NumberOutcome(100, 0));
        
        var outcome: NumberOutcome = question.outcomes[0];
        
        outcome.expectedResult = parseInt(e.target.value);

        question.outcomes[0] = outcome;

        context.setValues(values)
    }
    
    return (
        <div className="form-control w-full max-w-xs">
            <label htmlFor={`questionGroups[${groupIndex}].questions[${questionIndex}].outcomes[0].expectedResult`} className="label">
                <span className="label-text">Expected outcome</span>
            </label>
            <Field name={`questionGroups[${groupIndex}].questions[${questionIndex}].outcomes[0].expectedResult`}>
                {({ field, form }: FieldProps) => (
                        <input 
                        type="number" 
                        id={`questionGroups[${groupIndex}].questions[${questionIndex}].outcomes[0].expectedResult`} 
                        className="input input-bordered input-success w-full max-w-xs" 
                        onChange={e => recast(e, field, form.values)} 
                        value={(question.outcomes[0] as NumberOutcome).expectedResult}
                        />
                    )
                }
            </Field>
        </div>
    )

} 