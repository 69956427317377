import { ReactNode } from "react";

export const Header = ({ title, buttons }: { title: String, buttons?: ReactNode }) => {
    return (
    <header className="bg-white shadow">
        <div className="flex mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
            <div className="w-full mr-4">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900">{title}</h1>
            </div>
            { buttons }
        </div>
    </header>);
}