import { Field, useFormikContext } from "formik";
import * as React from "react";
import { HealthcheckModel } from "../../../models/HealthcheckModel";

export const BaseHealthCheckInfoForm = () => {
    const context = useFormikContext<HealthcheckModel>();

    return (
        <div className="bg-white rounded-lg p-4">
            <div className="grid grid-cols-1">
                <div className="form-control w-full">
                    <label htmlFor="title" className="label font-semibold">
                        <span className="label-text">Title</span>
                    </label>
                    <Field type="text" value={context.values.title} placeholder="Title" id="title" name="title" className="input input-bordered w-full max-w-xs" />
                    {context.touched.title && context.errors.title && <div className="inputError">{context.errors.title}</div>}

                    <label htmlFor="description" className="label font-semibold">
                        <span className="label-text">Description</span>
                    </label>
                    <Field as="textarea" value={context.values.description} placeholder="Description" id="description" name="description" className="textarea textarea-bordered h-24" />
                    {context.touched.description && context.errors.description && <div className="inputError">{context.errors.description}</div>}
                </div>
            </div>
        </div>
    )

}