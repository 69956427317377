import { PlusIcon } from "@heroicons/react/24/outline";
import { useFormikContext } from "formik";
import * as React from "react";
import { HealthcheckModel } from "../../../models/HealthcheckModel";
import { QuestionGroupForm } from "./QuestionGroupForm";

export const QuestionGroupsForm = () => {
    const context = useFormikContext<HealthcheckModel>();

    function addGroup(values: HealthcheckModel) {
        values.questionGroups.push({
            title: "",
            description: "",
            questions: []
        });

        context.setValues(values)
    }

    function removeGroup(values: HealthcheckModel, index: number) {
        values.questionGroups.splice(index, 1)
        context.setValues(values)
    }

    return (    
        <div className="grid grid-cols-1">
            {context.values.questionGroups!.map((group, i) => (
                <QuestionGroupForm key={i} group={group} groupIndex={i} onDelete={removeGroup}/>
            ))}
            <div className="bg-white rounded-lg p-4">
                <div className="grid grid-cols-1">
                    <button type="button" className="btn gap-2 m-8 btn-success" onClick={() => addGroup(context.values)}>
                        <PlusIcon className="h-6 w-6"/>
                        Add group
                    </button>
                </div>
            </div>
            
        </div>

    )

}